import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import AbaciLoader from "./components/AbaciLoader/AbaciLoader";
import { setLogin } from "./store/auth";
import Unauthorized from "./containers/Pages/Error/Unauthorized";
import { authAxios } from "./axiosInstance";
import ErrorPage from "./containers/Pages/Error";
import useUnAuthorizedHandler from "./hooks/useUnAuthorizedHandler";
import WebsocketProvider from "./components/WebsocketWrapper/Websocket";

export default function PrivateRoute(props) {

  const logoutHandler = useUnAuthorizedHandler();
  const [error, setError] = useState(null);
  const auth = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.loggedIn === null) {
      const token = Cookies.get("token");
      if (typeof token === "undefined") {
        logoutHandler();
      } else {
        const url = "/users_api/profile";
        authAxios
          .get(url)
          .then((response) => {
            if (response.data.is_active) {
              dispatch(setLogin({ user: response.data }));
            } else {
              logoutHandler();
            }
          })
          .catch((error) => {
            if (
              error.message === "timeout of 10000ms exceeded" ||
              error.message === "Network Error"
            ) {
              setError(500);
            } else {
              const errorStatusTemp = error.response.status;
              if (errorStatusTemp === 401 || errorStatusTemp === 403) {
                logoutHandler();
              } else {
                setError(errorStatusTemp);
              }
            }
          });
      }
    }
  });
  if (error !== null) {
    return <ErrorPage error={error} />;
  } else if (auth.loggedIn) {
    // return <></>
    if (props.allowedTo.includes(auth.profile.user_type)) {
    return <WebsocketProvider>{props.children}</WebsocketProvider>
    }
    else{
      return <Unauthorized />
    }
  } else {
    return <AbaciLoader />;
  }
}
