import { configureStore } from "@reduxjs/toolkit";
import layoutSlice from "./layout";
import authSlice from "./auth";
import DashboardSlice from './dashboard'
import ClientSlice from './client'
import notificationSlice from './notifications'
import websocketSlice from './websocket'

const store = configureStore({
  reducer: {
    layoutSlice: layoutSlice,
    authSlice: authSlice,
    ClientSlice: ClientSlice,
    DashboardSlice: DashboardSlice,
    notificationSlice:notificationSlice,
    websocketSlice:websocketSlice
  },
});

export default store;
