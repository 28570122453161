import React, { useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import TimerIcon from "@mui/icons-material/AccessAlarm";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Toastr } from "../../helpers/Alert";
import useUnAuthorizedHandler from "../../hooks/useUnAuthorizedHandler";
import Error from "../../helpers/Error";
import { readNotification, updateNotifications } from "../../store/notifications";

export const NotificationCard = ({ data, removeNotification }) => {
  const dispatch = useDispatch();
  const logoutHandler = useUnAuthorizedHandler();
  const notification =  useSelector(
    (state) => state.notificationSlice.notifications
  );

  const [showMessage, setShowMessage] = useState(false);
  const messagesShowHandler = () => {
    setShowMessage(!showMessage);
  };
  // const socket = useContext(WebsocketContext);
  useEffect(() => {
    if (showMessage && data?.id && !data?.is_read) {
      const url = `/abacicount/alerts/${data?.id}`;
      axios
        .patch(url, { is_read: true })
        .then(() => {
          dispatch(readNotification());
          const updatedNotifications = notification.map(
            (notification) => {
              if (notification?.id === data?.id) {
                // Update the 'isRead' property to true for the specific notification
                return { ...notification, is_read: true };
              }
              // Keep the other notifications unchanged
              return notification;
            }
          );
          // setTimeout(() => {
          //   removeNotification(data.id);
          // }, 10000);
          dispatch(updateNotifications(updatedNotifications));
        })
        .catch((error) => {
          const err_msg = Error(error,logoutHandler);
          Toastr("error", err_msg);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMessage]);


  return (
    <div
      className="notification-card"
      key={data?.id}
      onClick={() => messagesShowHandler()}
    >
      <div className="d-flex justify-content-between">
        <span className="d-flex">
          {data.alert_type === "Connection Alert" ? (
            <CameraAltIcon
              style={{ fontSize: "15px", marginTop: "3px", marginLeft: "2px" }}
            />
          ) : data.alert_type === "Timeout" ? (
            <TimerIcon
              style={{ fontSize: "17px", marginTop: "3px", marginLeft: "2px" }}
            />
          ) : (
            <WarningAmberIcon
              style={{
                fontSize: "17px",
                marginTop: "3px",
                marginLeft: "2px",
                color: "red",
              }}
            />
          )}
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginLeft: "5px",
            }}
          >
            {data?.alert_type}
          </p>
        </span>
        {data.is_read ? (
          <i
            className="ri-close-line"
            onClick={() => removeNotification(data?.id)}
          ></i>
        ) : (
          <i
            className="ri-checkbox-blank-circle-fill mt-1"
            style={{
              fontSize: "8px",
              color: "#4F72B7",
            }}
          ></i>
        )}

        {/* <div>{showMessage ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</div> */}
      </div>

      {showMessage ? (
        <p
          style={{
            overflow: "hidden",
            width: "15rem",
            marginTop: "-10px",
            fontSize: "13px",
            paddingLeft: "25px",
          }}
        >
          {data.alert_message}
        </p>
      ) : (
        <p
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "15rem",
            marginTop: "-10px",
            fontSize: "13px",
            paddingLeft: "25px",
          }}
        >
          {data.alert_message}
        </p>
      )}

      <div
        className="d-flex "
        style={{ paddingLeft: "25px", marginTop: "-15px" }}
      >
        <i className="ri-time-line"></i>
        <p
          style={{
            fontSize: "12px",
            marginLeft: "2px",
            marginTop: "2px",
          }}
        >
          {moment(data.time).startOf("hour").fromNow()}
        </p>
      </div>
    </div>
  );
};
