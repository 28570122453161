import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    device_online:null
};

const websocketSlice = createSlice({
    name: 'websocket',
    initialState,
    reducers:{
        setDevice(state,action) {
            state.device_online=action.payload
        },

    }
})

export const {  setDevice } = websocketSlice.actions;
export default websocketSlice.reducer;