import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import Modals from "../../../../components/Modal";
import { authAxios } from "../../../../axiosInstance";
import Error from "../../../../helpers/Error";
import { ErrorAlert, Toastr } from "../../../../helpers/Alert";
import { generateUniqueID, numberInvalidCharacterPreventer } from "../../../../helpers/functions";
import useUnAuthorizedHandler from "../../../../hooks/useUnAuthorizedHandler";
import { setClientDetail } from "../../../../store/client";
import Swal from "sweetalert2";


function AddDevice(props) {

  const dispatch = useDispatch();
  const clientDetail = useSelector((state) => state.ClientSlice.clientDetail);
  const logoutHandler = useUnAuthorizedHandler();
  const [timeZoneArray, setTimeZoneArray] = useState(null);
  const [loading,setIsLoading] = useState(true)
  const [pricingData,setPricingData] = useState(null);
  const [editActive,setEditActive] = useState(false);
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

    useEffect(() => {
    setIsLoading(true);
    const deviceID = generateUniqueID();
    setValue('device_id',deviceID);

    let temp = [];
    authAxios
      .get("/abacicount/timezone_list")
      .then((res) => {
        res.data.forEach((data) => {
          temp.push({ label: data, value: data });
        });
        setTimeZoneArray(temp);
        setIsLoading(false);
      })
      .catch((err) => {
        Toastr("error", Error(err, logoutHandler));
        setIsLoading(false);
      });

      authAxios
      .get("/abacicount/active_pricing")
      .then((res) => {
      setPricingData(res.data)
      if(res.data.sensor_cloud_license_cost === null || res.data.sensor_license_cost=== null || res.data.site_license_cost === null){
        ErrorAlert('Please add the pricing details to proceed with adding a device.')
        props.toggle()
      }
      })
      .catch((err) => {
        Toastr("error", Error(err, logoutHandler));
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {

    setwaitingForAxios(true);
    let formData = new FormData();
    formData.append('client',id)
    formData.append("device_id", data.device_id);
    formData.append("amount", data.amount);
    // formData.append("device_timezone", selectedTimeZone?.value);

    if (data.no_of_sensors !== "") {
      formData.append("no_of_sensors", data.no_of_sensors);
    }
    if (data.no_of_sites !== "") {
      formData.append("no_of_sites", data.no_of_sites);
    }

    if(data.remarks !==''){
      formData.append("remarks", data.remarks);
    }

    let url = "/abacicount/clientdevice";
    authAxios
      .post(url, formData)
      .then(() => {
        props.tableRef.current.onQueryChange();
        setwaitingForAxios(false);

        let dataForRedux = {...clientDetail,
                            total_no_of_sites:Number(clientDetail.total_no_of_sites)+Number(data.no_of_sites),
                            total_no_of_sensors:Number(clientDetail.total_no_of_sensors)+Number(data.no_of_sensors)
                            }

        dispatch(setClientDetail(dataForRedux))
        props.setRevenue((previousRevenue) => Number(previousRevenue)+Number(data.amount))
        Toastr("success", "New device successfully created!!");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        let error_msg = Error(error,logoutHandler);
        ErrorAlert(error_msg);
      });
  };

  const handleInputChange = (inputData,type) => {
    let no_of_sites = getValues('no_of_sites')
    let no_of_sensors = getValues('no_of_sensors')

    if(type === 'site'){
      no_of_sites = inputData
    }
    else{
      no_of_sensors = inputData
    }

    if(pricingData){
      const amount =((no_of_sites * pricingData.site_license_cost) + (no_of_sensors * pricingData.sensor_license_cost) + (pricingData.sensor_cloud_license_cost*no_of_sensors))
      setValue("amount",amount)
    }   
  }

  const editHandler = () =>{
    Swal.fire({
      title: "Are you sure?",
      // text: "You will not be able to revert this !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes !",
    }).then((result) => {
      if (result.isConfirmed) {
        setEditActive(true)
      }
    })
  }


  return (
    <Modals
      isOpen={props.isOpen}
      toggle={props.toggle}
      title={props.title}
      size={props.size}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Device id</Label>
          <Col>
            <input
              placeholder="Device id"
              type="number"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              onWheel={(e) => e.target.blur()}
              onKeyDown={(e) => {
                numberInvalidCharacterPreventer(e);
              }}
              {...register("device_id", {
                required: true,
              })}
            />
            {errors.device_id?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        {/* <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Device timezone</Label>
          <Col>
            <Select
              placeholder="Select TimeZone"
              options={timeZoneArray}
              onChange={setSelectedTimeZone}
              styles={customStyles}
              value={selectedTimeZone}
              isLoading={loading}
            />
          </Col>
        </FormGroup> */}
       
        <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>No Of Sites</Label>
                    <Col>
                      <input
                        placeholder="No Of Sites"
                        type="number"
                        style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                        className="form-control"
                        {...register("no_of_sites", {
                          required: true,
                          onChange:e => handleInputChange(e.target.value,'site')
                        })}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          numberInvalidCharacterPreventer(e);
                        }}
                      />
                      {errors.no_of_sites?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>No Of Sensors</Label>
                    <Col>
                      <input
                        placeholder="No Of Sensors"
                        type="number"
                        style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                        className="form-control"
                        {...register("no_of_sensors", {
                          required: true,
                          onChange:e => handleInputChange(e.target.value,'sensor')
                        })}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          numberInvalidCharacterPreventer(e);
                        }}
                      />
                      {errors.no_of_sensors?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup style={{ marginBottom: "5px" }}>
                  <Label sm={12}>Amount {!editActive && <EditIcon style={{height:"16px",marginLeft:0,marginTop:'-2px',cursor:'pointer'}} onClick={editHandler}/>}</Label>
                  <Col>
                    <input
                      placeholder="Amount"
                      type="number"
                      style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                      className="form-control"
                      disabled={!editActive}
                      {...register("amount", {
                        required: true,
                      })}
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) => {
                        numberInvalidCharacterPreventer(e);
                      }}
                    />
                    {errors.amount?.type === "required" && (
                      <span style={{ color: "red" }}>
                        *This field is required
                      </span>
                    )}
                  </Col>
                </FormGroup>

              {editActive &&
                <FormGroup style={{ marginBottom: "5px" }}>
                <Label sm={3}>Remarks</Label>
                <Col>
                  <textarea
                    placeholder="Remarks"
                  rows={4}
                  cols={4}
                    style={{ backgroundColor: "#FAFAFA" }}
                    className="form-control"
                    {...register("remarks", {
                      required: true,
                    })}
                  />
                  {errors.remarks?.type === "required" && (
                    <span style={{ color: "red" }}>*This field is required</span>
                  )}
                </Col>
              </FormGroup>
              }
               
        <ModalFooter>
          <Button
            type="submit"
            disabled={
              waitingForAxios 
            }
            className="w-xs waves-effect waves-light me-1"
          >
            {waitingForAxios ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            type="button"
            color="danger"
            className="w-xs waves-effect waves-light me-1"
            onClick={props.toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Form>
      {/* } */}
    </Modals>
  );
}

export default AddDevice;
