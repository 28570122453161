import React, { useEffect, useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Row, Col, Button, Alert, Container, Label, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import logodark from "../../../assets/images/logo-dark.png";
import { publicAxios, updateToken } from "../../../axiosInstance";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import AbaciLoader from "../../../components/AbaciLoader/AbaciLoader";
import { setLogin, setLogOut } from "../../../store/auth";
import { useNavigate } from "react-router-dom";
import { APP_NAME } from "../../../helpers/Constants";
import { Toastr } from "../../../helpers/Alert";
import axios from "axios";
import ErrorPage from "../Error";

function Login() {
  const [waitingForAxis, setWaitingForAxis] = useState(false);
  const [error, setError] = useState(null);
  const auth = useSelector((state) => state.authSlice);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const token = Cookies.get("token");
    if (typeof token === "undefined") {
      dispatch(setLogOut());
    } else {
      const url = "/users_api/profile";
      axios
        .get(url)
        .then((response) => {
          if (response.data.user_status === "Activated") {
            dispatch(setLogin({ user: response.data }));
            navigate("/clients");
          } else {
            Cookies.remove("token");
            dispatch(setLogOut());
          }
        })
        .catch((error) => {
          if (
            error.message === "timeout of 10000ms exceeded" ||
            error.message === "Network Error"
          ) {
            setError(500);
          } else {
            const errorStatusTemp = error.response.status;
            if (errorStatusTemp === 401) {
              Cookies.remove("token");
              dispatch(setLogOut());
              navigate("/login");
            } else {
              dispatch(setLogOut());
              navigate("/login");
              // setError(errorStatusTemp)
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    setWaitingForAxis(true);
    const url = "/users_api/login";
    publicAxios
      .post(url, data)
      .then((response) => {
        setWaitingForAxis(false);

        // const tenant = response.data?.tenants[0];
        // document.cookie = `tenant=${JSON.stringify(tenant)}`;
        updateToken(response.data.token);
        dispatch(setLogin(response.data));
        navigate("/clients");
      })
      .catch((error) => {
        dispatch(setLogOut());
        let errorMessage = null;
        if (error.response?.status === 403) {
          errorMessage = error?.response?.data?.error;
        } else {
          errorMessage =
            "Error occured, please check your connection and try again!";
        }
        setWaitingForAxis(false);
        Toastr("error", errorMessage);
      });
  };
  if (error !== null) {
    return <ErrorPage error={error} />;
  }
  if (auth.loggedIn === null || auth.loggedIn) {
    return <AbaciLoader />;
  } else {
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/">
                                <img
                                  src={logodark}
                                  alt=""
                                  height="40"
                                  className="auth-logo logo-dark mx-auto mt-2"
                                />
                                <img
                                  src={logodark}
                                  alt=""
                                  height="40"
                                  className="auth-logo logo-light mx-auto mt-2"
                                />
                              </Link>
                            </div>
                            <h4 className="font-size-18 mt-4 noselect">
                              Welcome !
                            </h4>
                            <p className="text-muted noselect">
                              Sign in to continue to {APP_NAME}
                            </p>
                          </div>
                          {false ? (
                            <Alert color="danger">{"halo"}</Alert>
                          ) : null}
                          <div className="p-2 mt-5">
                            <form
                              className="form-horizontal"
                              onSubmit={handleSubmit(onSubmit)}
                            >
                              {/* register your input into the hook by invoking the "register" function */}
                              <div className="auth-form-group-custom mb-4 noselect">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="email">Email</Label>
                                <input
                                  className="form-control"
                                  placeholder="Enter email"
                                  {...register("username", {
                                    required: true,
                                    pattern:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  })}
                                />

                                {errors.email?.type === "required" && (
                                  <span
                                    style={{
                                      color: "#bf3d48",
                                    }}
                                  >
                                    This field is required
                                  </span>
                                )}
                                {errors.username?.type === "pattern" && (
                                  <span
                                    style={{
                                      color: "#bf3d48",
                                    }}
                                  >
                                    Please provide a valid email address
                                  </span>
                                )}
                              </div>
                              <div className="auth-form-group-custom mb-4 noselect">
                                <span
                                  style={{
                                    position: "absolute",
                                    top: 19,
                                    right: 20,
                                    cursor: "pointer",
                                  }}
                                  onClick={togglePasswordVisibility}
                                >
                                  {showPassword ? (
                                    <VisibilityOffIcon
                                      style={{
                                        width: "30px",
                                        height: "20px",
                                      }}
                                    />
                                  ) : (
                                    <VisibilityIcon
                                      style={{
                                        width: "30px",
                                        height: "20px",
                                      }}
                                    />
                                  )}
                                </span>
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="username">Password</Label>
                                <input
                                  className="form-control"
                                  placeholder="Enter password"
                                  type={showPassword ? "text" : "password"}
                                  {...register("password", {
                                    required: true,
                                  })}
                                />
                                {errors.password && (
                                  <span
                                    style={{
                                      color: "#bf3d48",
                                    }}
                                  >
                                    Please provide your password
                                  </span>
                                )}
                              </div>

                              <div className="mt-4 text-center">
                                <Button
                                  style={{
                                    backgroundColor: "#FFCD29",
                                    color: "black",
                                    border: "0",
                                  }}
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  {waitingForAxis ? (
                                    <Spinner animation="grow" size="sm" />
                                  ) : (
                                    <span style={{ color: "black" }}>
                                      Log In
                                    </span>
                                  )}
                                </Button>
                                {/* <p style={{ color: "red" }}>{responseError}</p> */}
                              </div>
                              {/* <div className="mt-4 text-center noselect">
                                  <Link to="/forgotpassword" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                                </div> */}
                            </form>
                          </div>

                          {/* } */}
                          <div className="mt-5 text-center noselect">
                            <p>
                              © {new Date().getFullYear()} {APP_NAME}.
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
