import React, { useState, useRef } from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
import MaterialTable from "material-table";
import { APP_NAME } from "../../../helpers/Constants";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { tableIcons } from "../../../helpers/MaterialIcons";
import { authAxios } from "../../../axiosInstance";
import Moments from "../../../helpers/Moment";

export default function EmailLog() {
  const tableRef = useRef();
  const totalRecordsCount = useRef(0);
  const urlBackup = useRef("");
  const [filterEnabled] = useState(false);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "Email Log", link: "#" },
    ],
  });

  const columns = [
    {
      title: "Sl No",
      field: "sl_no",
    },
    {
      title: "Recipient Name",
      field: "recipient",
    },
    {
        title: "Sender",
        field: "sender",
    },
    {
        title: "Email Subject",
        field: "email_subject",
    },
    {
      title: "Created Date",
      field: "created_date",
      render: (rowData) => (
        <>
          {rowData.created_date
            ? Moments(rowData.created_date, "datetime")
            : "----"}
        </>
      ),
    },
    { title: "Status", field: "email_sent_status", render:rowData =>(<>{rowData.email_sent_status ? "Success" : "Failed"}</> )},

  ];



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Email Log"
            breadcrumbItems={dashboardState.breadcrumbItems}
          />
          <Col xl={24}>
            <Card style={{ minHeight: "525px" }}>
              <CardBody>

                <div
                  style={{
                    overflowY: "auto",
                  }}
                >
                  <MaterialTable
                    title=" "
                    columns={columns}
                    tableRef={tableRef}
                    data={(query) => {
                      return new Promise((resolve, reject) => {
                        let orderBy = "";

                        if (query.orderBy) {
                          orderBy =
                            query.orderDirection === "asc"
                              ? `&ordering=-${query.orderBy?.field}`
                              : `&ordering=${query.orderBy?.field}`;
                        }

                        let url = `/abacicount/email_log?limit=${
                          query.pageSize
                        }&offset=${query.pageSize * query.page}&search=${
                          query.search
                        }${orderBy}`;


                        urlBackup.current = url;

                        authAxios
                          .get(url)
                          .then((response) => {
                            totalRecordsCount.current = response.data?.count;
                            const tempList = response.data?.results.map(
                              (data, idx) => ({ ...data, sl_no: idx + 1 })
                            );

                            resolve({
                              data: tempList,
                              page: query.page,
                              totalCount: response.data?.count,
                            });
                          })
                          .catch((error) => {
                            reject({
                              data: [],
                              page: query.page,
                              totalCount: 0,
                            });
                          });
                      });
                    }}
                    actions={[]}
                    options={{
                      exportFileName: "Users List",
                      exportAllData: false,
                      actionsColumnIndex: -1,
                      exportButton: false,
                      headerStyle: {
                        zIndex: 0,
                      },
                     
                      debounceInterval: 500,
                      filtering: filterEnabled,
                    }}
                    icons={tableIcons}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
}
