import React, {  useEffect } from "react";
import Modal from "../../components/Modal";
import axios from "axios";
import { Toastr } from "../../helpers/Alert";
import Error from "../../helpers/Error";
import { useDispatch, useSelector } from "react-redux";
// import { readNotification } from "../../store/notificationsSlice";
import { deleteNotifications, readNotification } from "../../store/notifications";
// import { WebsocketContext } from "../../../Context/WebSocketContext";

function NotificationModal(props) {
//   const socket = useContext(WebsocketContext);
  const notification_count = useSelector(
    (state) => state.notificationSlice.notifications_count
  );
  const notification =  useSelector(
    (state) => state.notificationSlice.notifications
  );

  const dispatch = useDispatch();

  const removeNotificationFromDropDown = (id) => {
    dispatch(deleteNotifications(id))

    // const temp = socket.notifications.filter((data) => data.id !== id);

    // socket.setNotifications(temp);
  };

  useEffect(() => {

    if (props.item && !props.item.is_read) {

      const url = `/abacicount/alerts/${props.item.id}`;
      axios
        .patch(url, { is_read: true })
        .then((response) => {

          if(notification.length > 0 && notification_count > 0){
            dispatch(readNotification());
            removeNotificationFromDropDown(props.item.id)
            }

          props.tableRef.current.onQueryChange();
        })
        .catch((error) => {
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        title={props.title}
        NotCentered
        size={"xl"}
      >
        <div>
          <div
            style={{
              width: "100%",
              minHeight: "90px",
              backgroundColor: "#F1F5F7",
              border: "1px solid #C0C0C0",
              fontSize: "15px",
              fontWeight: "bold",
              textAlign: "center",
              paddingTop: "30px",
            }}
          >
            {props.item.alert_message}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default NotificationModal;
