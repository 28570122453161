import React, { useEffect,useState } from "react";
import { Button, Spinner } from "reactstrap";
import Modals from "../../../components/Modal";
import Error from "../../../helpers/Error";
import { ErrorAlert, Toastr } from "../../../helpers/Alert";
import useUnAuthorizedHandler from "../../../hooks/useUnAuthorizedHandler";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
function AddReleaseNotes(props) {
  const logoutHandler = useUnAuthorizedHandler();
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const [releaseNote, setReleaseNote] = useState(null);
  const [update, setUpdate] = useState(false);
  const onSubmit = async (type) => {
    setwaitingForAxios(true);
    let url = "";
    if (update) {
      url = `/abacicount/release_note/${props.item.version_release_note.id}`;
    } else {
      url = "/abacicount/release_note";
    }
    const method = update ? "PUT" : "POST";
    axios({
      method: method,
      url: url,
      data: {
        release_note: releaseNote,
        version: props.item.id,
      },
    })
      .then((response) => {
        setwaitingForAxios(false);
        Toastr(
          "success",
          update
            ? "Release note updated  successfully!! "
            : "New release note added successfully!!"
        );
        props.tableRef.current.onQueryChange();

        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        let error_msg = Error(error, logoutHandler);
        ErrorAlert(error_msg);
      });
  };

  useEffect(() => {
    if (props.item?.version_release_note?.release_note) {
      setReleaseNote(props.item.version_release_note.release_note);
      setUpdate(true);
    }
  }, [props]);

  return (
    <Modals
      isOpen={props.isOpen}
      toggle={props.toggle}
      title={props.title}
      size={props.size}
    >
      <ReactQuill
        theme="snow"
        value={releaseNote}
        onChange={setReleaseNote}
        style={{ height: "500px" }}
      />

      <div
        className="d-flex justify-content-end mt-5 "
        style={{ paddingTop: "5px" }}
      >
        {/* <Button type="submit" className="w-xs waves-effect waves-light me-1">
          {waitingForAxios ? (
            <Spinner animation="grow" size="sm" />
          ) : (
            " Save & Download"
          )}
          Save & Download
        </Button> */}
        <Button
          type="submit"
          disabled={waitingForAxios || !releaseNote}
          className="w-xs waves-effect waves-light me-1"
          onClick={() => onSubmit()}
        >
          {waitingForAxios ? <Spinner animation="grow" size="sm" /> : "Save"}
        </Button>
      </div>
    </Modals>
  );
}

export default AddReleaseNotes;
