import React, { useState, useRef } from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
import MaterialTable from "material-table";
import { APP_NAME } from "../../../helpers/Constants";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { tableIcons } from "../../../helpers/MaterialIcons";
import { authAxios } from "../../../axiosInstance";
import { CustomButton } from "../../../components/CustomButton";
import AddVersion from "./AddVersion";
import Moments from "../../../helpers/Moment";
import Edit from "@material-ui/icons/Edit";
import EditVersion from "./EditVersion";
import ReleaseVersionDetails from "./ReleaseVersionDetails";
import AddReleaseNotes from "./AddReleaseNote";
import DescriptionIcon from "@mui/icons-material/Description";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
import useUnAuthorizedHandler from "../../../hooks/useUnAuthorizedHandler";
import { useSelector } from "react-redux";

export default function Versions() {
  const logoutHandler = useUnAuthorizedHandler();
  const tableRef = useRef();
  const profile = useSelector((state) => state.authSlice.profile);

  const totalRecordsCount = useRef(0);
  const urlBackup = useRef("");
  const [addModalShow, setAddModalShow] = useState(false);
  const [addReleaseNoteModalShow, setAddReleaseNoteModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [releaseModalShow, setReleaseModalShow] = useState(false);
  const [itemToBeEdited, setItemToBeEdited] = useState(null);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "Versions", link: "#" },
    ],
  });

  const addModalToggle = () => {
    setAddModalShow(!addModalShow);
  };
  const editModalToggle = () => {
    setEditModalShow(!editModalShow);
  };
  const releaseModalToggle = () => {
    setReleaseModalShow(!releaseModalShow);
  };
  const addReleaseNoteModalToggle = () => {
    setAddReleaseNoteModalShow(!addReleaseNoteModalShow);
  };

  const columns = [
    {
      title: "Sl No",
      field: "sl_no",
    },
    {
      title: "Version Code",
      field: "version",
    },
    {
      title: "Created Date",
      field: "created_date",
    },
  ];

  return (
    <React.Fragment>
      {addModalShow && (
        <AddVersion
          isOpen={addModalShow}
          toggle={addModalToggle}
          title="Add Version"
          tableRef={tableRef}
        />
      )}
      {addReleaseNoteModalShow && (
        <AddReleaseNotes
          isOpen={addReleaseNoteModalShow}
          toggle={addReleaseNoteModalToggle}
          title="Release Note"
          item={itemToBeEdited}
          size="xl"
          tableRef={tableRef}
        />
      )}
      {editModalShow && (
        <EditVersion
          isOpen={editModalShow}
          toggle={editModalToggle}
          title="Edit Version"
          item={itemToBeEdited}
          tableRef={tableRef}
        />
      )}
      {releaseModalShow && (
        <ReleaseVersionDetails
          isOpen={releaseModalShow}
          toggle={releaseModalToggle}
          title="Release Notes"
          item={itemToBeEdited}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Versions"
            breadcrumbItems={dashboardState.breadcrumbItems}
          />
          <Col xl={24}>
            <Card style={{ minHeight: "525px" }}>
              <CardBody>
                <div
                  style={{
                    overflowY: "auto",
                  }}
                >
                  <MaterialTable
                    title=" "
                    columns={columns}
                    tableRef={tableRef}
                    data={(query) => {
                      return new Promise((resolve, reject) => {
                        let orderBy = "";

                        if (query.orderBy) {
                          orderBy =
                            query.orderDirection === "asc"
                              ? `&ordering=-${query.orderBy?.field}`
                              : `&ordering=${query.orderBy?.field}`;
                        }

                        let url = `/abacicount/version?limit=${
                          query.pageSize
                        }&offset=${query.pageSize * query.page}&search=${
                          query.search
                        }${orderBy}`;

                        urlBackup.current = url;

                        authAxios
                          .get(url)
                          .then((response) => {
                            totalRecordsCount.current = response.data?.count;
                            const tempList = response.data?.results.map(
                              (data, idx) => ({
                                ...data,
                                sl_no: idx + 1,
                                created_date: Moments(
                                  data.created_date,
                                  "datetime"
                                ),
                              })
                            );

                            resolve({
                              data: tempList,
                              page: query.page,
                              totalCount: response.data?.count,
                            });
                          })
                          .catch((error) => {
                            Toastr("error", Error(error, logoutHandler));
                            reject({
                              data: [],
                              page: query.page,
                              totalCount: 0,
                            });
                          });
                      });
                    }}
                    actions={[
                      // {
                      //   icon: AddIcon,
                      //   tooltip: "Add Release Note",
                      //   onClick: (event, rowData) => {
                      //     setItemToBeEdited(rowData);
                      //     addReleaseNoteModalToggle();
                      //   },
                      // },
                      {
                        icon: DescriptionIcon,
                        tooltip: "View Release Note",
                        onClick: (event, rowData) => {
                          setItemToBeEdited(rowData);
                          addReleaseNoteModalToggle();
                        },
                      },
                      {
                        icon: Edit,
                        tooltip: "Edit Version",
                        onClick: (event, rowData) => {
                          setItemToBeEdited(rowData);
                          editModalToggle();
                        },
                        hidden: profile.user_type !== "Admin",
                      },
                    ]}
                    // onRowClick={(event, rowData) => rowClickHandler(rowData)}

                    options={{
                      actionsColumnIndex: -1,
                      exportButton: false,
                      headerStyle: {
                        zIndex: 0,
                      },
                      debounceInterval: 500,
                      filtering: false,
                    }}
                    icons={tableIcons}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          {profile.user_type === "Admin" && (
            <CustomButton
              onClick={() => {
                addModalToggle();
              }}
              title={"+"}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}
