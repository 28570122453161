import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APP_NAME, truncateString } from "../../helpers/Constants";
import MaterialTable from "material-table";
import { tableIcons } from "../../helpers/MaterialIcons";
import axios from "axios";
import Moments from "../../helpers/Moment";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import TimerIcon from "@mui/icons-material/AccessAlarm";
import useUnAuthorizedHandler from "../../hooks/useUnAuthorizedHandler";
import Error from "../../helpers/Error";
import NotificationModal from "./NotificationsModal";

export default function NotificationsPage() {
  const [pageSize, setPageSize] = useState(5);
  const [modalShow, setModalShow] = useState(false);
  const [rowData, setRowData] = useState({});
  const tableRef = useRef();
  const [loader, setloading] = useState(true);
  const totalRecordsCount = useRef(0);
  const urlBackup = useRef();
  const logoutHandler = useUnAuthorizedHandler();

  const [dashboardState, setDashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/" },
      { title: "Notifications", link: "#" },
    ],
  });

  useEffect(() => {
    const abortController = new AbortController();

    setDashboardState({
      breadcrumbItems: [
        { title: APP_NAME, link: "/" },
        { title: "Notifications", link: "#" },
      ],
    });

    return () => {
      abortController.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "Notification",
      field: "alert_message2",
      render: (rowData) =>
        !rowData.is_read ? (
          <>
            <i
              className="ri-checkbox-blank-circle-fill"
              style={{
                fontSize: "8px",
                color: "#4F72B7",
                marginRight: "5px",
              }}
            ></i>
            {rowData.alert_message2}
          </>
        ) : (
          <> {rowData.alert_message2}</>
        ),
    },
    { title: "Type", field: "alert_type" },
    { title: "Time", field: "time" },
  ];

  const ModalToggle = (data) => {
    setModalShow(!modalShow);
    setRowData(data);
  };


  

  return (
    <React.Fragment>
      {modalShow && (
        <NotificationModal
          title={
            <>
              {rowData.alert_type === "Connection Alert" ? (
                <CameraAltIcon
                  style={{
                    fontSize: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                />
              ) : (
                <TimerIcon
                  style={{
                    fontSize: "22px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                />
              )}
              {rowData.alert_type}
            </>
          }
          toggle={ModalToggle}
          isOpen={modalShow}
          item={rowData}
          tableRef={tableRef}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Notifications"
            breadcrumbItems={dashboardState.breadcrumbItems}
          />

          <Col xl={24}>
            <Card style={{ minHeight: "525px" }}>
              <CardBody>
                <div
                  style={{
                    overflowY: "auto",
                  }}
                >
                  <MaterialTable
                    title=" "
                    columns={columns}
                    onChangeRowsPerPage={setPageSize}
                    tableRef={tableRef}
                    data={(query) => {
                      return new Promise((resolve, reject) => {
                        // if(selected_site !== null){
                        let orderBy = "";
                        if (query.orderBy) {
                          orderBy =
                            query.orderDirection === "asc"
                              ? `&ordering=-${query.orderBy?.field}`
                              : `&ordering=${query.orderBy?.field}`;
                        }
                        let url = `/abacicount/alerts?limit=${
                          query.pageSize
                        }&offset=${query.pageSize * query.page}&search=${
                          query.search
                        }${orderBy}`;
                       

                        urlBackup.current = url;
                        axios
                          .get(url)
                          .then((response) => {
                            totalRecordsCount.current = response.data?.count;
                            const NotificationDetails = response.data?.results;
                            const TempData = NotificationDetails.map((data) => ({
                              ...data,
                              alert_type: data.alert_type
                                ? data.alert_type
                                : "-",

                              time: data?.time
                                ? Moments(data.time, "datetime")
                                : "-",
                              alert_message: data.alert_message,
                              alert_message2: truncateString(
                                data.alert_message
                              ),
                            }));
                            resolve({
                              data: TempData,
                              page:
                                response.data?.results.length === 0
                                  ? 0
                                  : query.page,
                              totalCount: response.data?.count,
                            });
                          })
                          .catch((error) => {
                            Error(error, logoutHandler);
                            reject({
                              data: [],
                              page: query.page,
                              totalCount: 0,
                            });
                          }).finally(() => {
                            setloading(false); // Indicate that the query is complete
                          });
                        
                      })
                 
                    }}
                    onRowClick={(event, rowData) => ModalToggle(rowData)}
                    options={{
                      pageSize: pageSize,
                      exportFileName: "Notifications",
                      actionsColumnIndex: -1,
                      exportButton: false,
                      debounceInterval: 500,
                    }}
                    icons={tableIcons}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
}
