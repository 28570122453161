import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Swal from 'sweetalert2'

export const Toastr = ($type, $msg) => {
    toastr.options = {
        closeButton: true,
        positionClass: "toast-top-full-width",
        preventDuplicates: false,
        onclick: null,
        showDuration: "300",
        hideDuration: "1000",
        timeOut: "3000",
        extendedTimeOut: "1000",
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut",
      };
      return toastr[$type]($msg);
}

export const RightSideToastr = ($type, $msg) => {
  toastr.options = {
      closeButton: true,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "3000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };
    return toastr[$type]($msg);
}

export const SuccessAlert = ($msg) => {
  Swal.fire(
    'Success!',
    $msg,
    'success'
  )
}
export const ErrorAlert = ($msg) => {
  Swal.fire(
    'Oops...',
    $msg,
    'error'
  )
}