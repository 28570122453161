import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Modals from "../../../components/Modal";
import { authAxios } from "../../../axiosInstance";
import Error from "../../../helpers/Error";
import { ErrorAlert, Toastr } from "../../../helpers/Alert";
import useUnAuthorizedHandler from "../../../hooks/useUnAuthorizedHandler";

function AddPricing(props) {

  const logoutHandler = useUnAuthorizedHandler();

  const [waitingForAxios, setwaitingForAxios] = useState(false);
  // const [confirmPassword, setConfirmPassword] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const passwordsMatch = () => {
    return watch("password") === watch("confirm_password");
  };

  const onSubmit = async (data) => {
    setwaitingForAxios(true);
    let formData = new FormData();

    // formData.append("country", data.country);
    formData.append("sensor_license_cost", data.sensor_license_cost);
    formData.append("site_license_cost ", data.site_license_cost );

    formData.append("sensor_cloud_license_cost ", data.sensor_cloud_license_cost);
    formData.append("currency ", data.currency);
    formData.append("remarks ", data.remarks);
 
    let url = "/abacicount/pricing";
    authAxios
      .post(url, formData)
      .then(() => {
        // dispatch(addNewClients(response.data))
        props.tableRef.current.onQueryChange();
        setwaitingForAxios(false);
        Toastr("success", "New Pricing Created Successfully!!");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        let error_msg = Error(error,logoutHandler);
        ErrorAlert(error_msg);
      });
  };

  return (
    <Modals
      isOpen={props.isOpen}
      toggle={props.toggle}
      title={props.title}
      size={props.size}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Sensor License Cost</Label>
          <Col>
            <input
              placeholder="Sensor License Cost"
              type="number"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("sensor_license_cost", {
                required: true,
              })}
            />
            {errors.sensor_license_cost?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Site License Cost</Label>
          <Col>
            <input
              placeholder="Site License Cost"
              type="number"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("site_license_cost", {
                required: true,
              })}
            />
            {errors.site_license_cost?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Sensor Cloud Cost</Label>
          <Col>
            <input
              placeholder="Sensor Cloud Cost"
              type="number"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("sensor_cloud_license_cost", {
                required: true,
              })}
            />
            {errors.sensor_cloud_license_cost?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Curreny Type</Label>
          <Col>
          <select
              className="form-control"
              {...register("currency", {
                required: true,
              })}
            >
              <option value=""> --Select Currency Type--</option>
              <option value="AED">AED</option>
              <option value="USD">USD</option>
              <option value="INR">INR</option>
            </select>
            {errors.currency?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Remarks</Label>
          <Col>
            <textarea
              placeholder="Remarks"
             rows={3}
             cols={3}
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("remarks", {
                required: true,
              })}
            />
            {errors.remarks?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
      
        <ModalFooter>
          <Button
            type="submit"
            disabled={waitingForAxios || !passwordsMatch()}
            className="w-xs waves-effect waves-light me-1"
          >
            {waitingForAxios ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            type="button"
            color="danger"
            className="w-xs waves-effect waves-light me-1"
            onClick={props.toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Form>
      {/* } */}
    </Modals>
  );
}

export default AddPricing;
