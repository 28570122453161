import React from "react";
import {
  Card,
  CardBody
} from "reactstrap";
import Modals from "../../../../components/Modal";
import IconButton from '@mui/material/IconButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Tooltip } from "@mui/material";
import { authAxios } from "../../../../axiosInstance";
import fileDownload from "js-file-download";

function ReportInfoModal(props) {

  const downloadAttachment = (url) => {
    // let file_url = baseURL + `/reports/${url.split('reports/')[1]}`
    let name = url.split('reports/')[1]
    authAxios.get(url, {
        responseType: 'blob',
      }).then(res => {
        fileDownload(res.data, name);
      });
    }

  return (
    <Modals
      isOpen={props.isOpen}
      toggle={props.toggle}
      title={props.title}
      size={props.size}
    >
        {props?.reportFiles && props?.reportFiles.map((data) => (
            <Card style={{width:'100%'}} >
                <CardBody className='d-flex justify-content-between'>
                    <p>{data?.pdf_file.split('reports/')[1]}</p>
                    <Tooltip title='Download Attachment'>
                    <IconButton size="small" onClick={() => downloadAttachment(data.pdf_file)}>
                        <FileDownloadIcon fontSize="inherit" />
                    </IconButton>
                    </Tooltip>
                </CardBody>
            </Card>
        ))}
    </Modals>
  );
}

export default ReportInfoModal;
