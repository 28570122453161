import React, { useState, useRef, useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { APP_NAME, currency } from "../../../../helpers/Constants";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { authAxios } from "../../../../axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import LeftDetailComponent from "../../../../components/ClientDetail/LeftDetailComponent";
import { formatNumber } from "../../../../helpers/functions";
import { setClientDetail } from "../../../../store/client";
import { useDispatch, useSelector } from "react-redux";
import AbaciLoader from "../../../../components/AbaciLoader/AbaciLoader";
import Error from "../../../../helpers/Error";
import { Toastr } from "../../../../helpers/Alert";
import useUnAuthorizedHandler from "../../../../hooks/useUnAuthorizedHandler";
import MaterialTable from "material-table";
import { exportData } from "../../../../helpers/fileDownload";
import { tableIcons } from "../../../../helpers/MaterialIcons";
import Moments from "../../../../helpers/Moment";
import { CustomButton } from "../../../../components/CustomButton";
import AddDevice from "./AddDevice";
import { setDevice } from "../../../../store/websocket";

export default function ClientDetail() {

  const navigate = useNavigate();
  const deviceOnlineStatus = useSelector(state => state.websocketSlice.device_online)
  const logoutHandler = useUnAuthorizedHandler();
  const profile = useSelector((state) => state.authSlice.profile);
  const clientDetail = useSelector((state) => state.ClientSlice.clientDetail);
  const dispatch = useDispatch();
  const [revenue, setRevenue] = useState("");
  const [addModalShow,setAddModalShow] = useState(false)
  const tableRef = useRef();
  const { id } = useParams();
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "#" },
      { title: "Clients", link: "/clients" },
      { title: "Client Details", link: "#" },
    ],
  });
  const totalRecordsCount = useRef(0);
  const urlBackup = useRef("");

  const addModalToggle = () => {
    setAddModalShow(!addModalShow)
  }

  useEffect(() => {
    if(deviceOnlineStatus?.client_id === clientDetail?.id){
        tableRef?.current?.onQueryChange();
       }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceOnlineStatus])
  

  useEffect(() => {
    // window.addEventListener('resize',resizeHandler)

    const url = `/abacicount/clientrud/${id}`;
    authAxios
      .get(url)
      .then((response) => {
        dispatch(setClientDetail(response.data.client));
        setRevenue(response?.data?.total_revenue)
      })
      .catch((error) => {
        const err_msg = Error(error, logoutHandler);
        Toastr("error", err_msg);
      });


    return () => {
      dispatch(setClientDetail(null));
      dispatch(setDevice(null));

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
  const columns = [

    {
      title: "Created Date",
      field: "created_date",
      render: (rowData) => (
        <>{rowData.created_date ? Moments(rowData.created_date,'datetime') : "----"}</>
      ),
    },
    {
      title: "Cloud Access No",
      field: "cloud_access_unique_number",
    },
    {
      title: "Device ID",
      field: "device_id",
      render: (rowData) => (
        <>{rowData.device_id ? rowData.device_id : "----"}</>
      ),
    },
    {
      title: "No of sites",
      field: "total_no_of_sites",
      render: (rowData) => (
        <>{rowData.total_no_of_sites ? rowData.total_no_of_sites : "----"}</>
      ),
    },
    {
      title: "No of sensors",
      field: "total_no_of_sensors",
      render: (rowData) => (
        <>
          {rowData.total_no_of_sensors ? rowData.total_no_of_sensors : "----"}
        </>
      ),
    },
   
    { title: "Status", field: "status" },
    {
      title: "Cloud Connection Status",
      field: "online_status",
      render: (rowData) => (
        <span
          className={
            rowData.online_status
              ? "badge badge-soft-success"
              : "badge badge-soft-danger"
          }
        >
          {rowData.online_status ? "Online" : "Offline"}
        </span>
      ),
    },
  ];



  if (clientDetail === null) return <AbaciLoader />;
  else {
    return (
      <React.Fragment>

      {addModalShow && (
        <AddDevice
          isOpen={addModalShow}
          toggle={addModalToggle}
          title={"Add New Device"}
          tableRef={tableRef}
          setRevenue={setRevenue}
        />
      )}

        <div className="page-content">
          <Container fluid>
            {/* <div style={{zIndex:9999}}> */}
            <Breadcrumbs
              title='Client Details'
              breadcrumbItems={dashboardState.breadcrumbItems}
            />
            {/* </div> */}
            {/* <p style={{ marginTop: "-15px" }}>
              Address : {clientDetail?.address ? clientDetail?.address : "-"}
            </p>
            <p style={{ marginTop: "-15px" }}>
              Timezone : {clientDetail?.client_timezone ? clientDetail?.client_timezone : "-"}
            </p>
            <p style={{ marginTop: "-15px" }}>
              Industry : {clientDetail?.industry ? clientDetail?.industry : "-"}
            </p> */}

              <div style={{ position: "relative", left: "60%", top: "-50px",marginBottom:"-40px" ,zIndex:'-1' }}>
              <p>Total Revenue</p>
              <h4 style={{ marginTop: "-10px"}}>
                {currency + ' '}
                {revenue ? formatNumber(revenue) : 0}
              </h4>
            </div>

            <Row>
              <Col xl={4}>
                <Card style={{ minHeight: "555px" }}>
                  <CardBody>
                    <LeftDetailComponent
                      tableRef={tableRef}
                      // activeTab={value}
                      // cloud_sync={clientDetail?.cloud_sync_valid_up_to}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xl={8}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                  <Row>
                    <p>Device List</p>
                  </Row>
            <div
                  style={{
                    overflowY: "auto",
                  }}
                >
                  <MaterialTable
                    title=""
                    columns={columns}
                    tableRef={tableRef}
                    onRowClick={(e, rowData) =>
                      navigate(`/devicedetails/${rowData.id}`, {
                        state: { client_name: rowData.name },
                      })
                    }
                    // isLoading={loading}
                    data={(query) => {
                      return new Promise((resolve, reject) => {
                        let orderBy = "";

                        if (query.orderBy) {
                          orderBy =
                            query.orderDirection === "asc"
                              ? `&ordering=-${query.orderBy?.field}`
                              : `&ordering=${query.orderBy?.field}`;
                        }

                        let url = `/abacicount/clientdevice?client_id=${id}&limit=${
                          query.pageSize
                        }&offset=${query.pageSize * query.page}&search=${
                          query.search
                        }${orderBy}`;

                     

                        urlBackup.current = url;

                        authAxios
                          .get(url)
                          .then((response) => {
                            totalRecordsCount.current = response.data?.count;
                            const tempList = response.data?.results.map(
                              (data, idx) => ({ ...data, sl_no: idx + 1 })
                            );

                            resolve({
                              data: tempList,
                              page: query.page,
                              totalCount: response.data?.count,
                            });
                          })
                          .catch((error) => {
                            reject({
                              data: [],
                              page: query.page,
                              totalCount: 0,
                            });
                          });
                      });
                    }}
                    actions={[]}
                    options={{
                      exportFileName: "Users List",
                      exportAllData: true,
                      actionsColumnIndex: -1,
                      exportButton: true,
                      headerStyle: {
                        zIndex: 0,
                      },
                      exportCsv: async () =>
                        exportData(
                          "csv_download",
                          urlBackup.current,
                          totalRecordsCount.current,
                          "Client List"
                        ),
                      exportPdf: async () =>
                        exportData(
                          "pdf_download",
                          urlBackup.current,
                          totalRecordsCount.current,
                          "Client List"
                        ),
                      debounceInterval: 500,
                      filtering: false,
                    }}
                    icons={tableIcons}
                  />
                </div>
                </CardBody>
                </Card>
                </Col>
                </Row>

                {profile.user_type === 'Admin' && clientDetail?.status === 'Active' && 
              <CustomButton
                onClick={() => {
                  addModalToggle();
                }}
                title={"+"}
              />
              }
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
