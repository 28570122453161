import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Modals from "../../../components/Modal";
import { authAxios } from "../../../axiosInstance";
import Error from "../../../helpers/Error";
import { ErrorAlert, Toastr } from "../../../helpers/Alert";
import useUnAuthorizedHandler from "../../../hooks/useUnAuthorizedHandler";

function EditPricing(props) {

  const logoutHandler = useUnAuthorizedHandler();

  const [waitingForAxios, setwaitingForAxios] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props.item !== null) {
      let temp = { ...props.item };
      reset(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    setwaitingForAxios(true);
    let formData = new FormData();

    // formData.append("country", data.country);
    formData.append("sensor_license_cost", data.sensor_license_cost);
    formData.append("site_license_cost", data.site_license_cost );

    formData.append("sensor_cloud_license_cost", data.sensor_cloud_license_cost);
    formData.append("currency", data.currency);
    formData.append("remarks ", data.remarks);

    let url = `/abacicount/pricing/${props.item.id}`;

    authAxios
      .patch(url, formData)
      .then((response) => {
        // dispatch(addNewClients(response.data))
        props.tableRef.current.onQueryChange();
        setwaitingForAxios(false);
        Toastr("success", "User details updated successfully!!");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        let error_msg = Error(error,logoutHandler);
        ErrorAlert(error_msg);
      });
  };

  return (
    <Modals
      isOpen={props.isOpen}
      toggle={props.toggle}
      title={props.title}
      size={props.size}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Sensor License Cost</Label>
          <Col>
            <input
              placeholder="sensor license cost"
              type="text"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("sensor_license_cost", {
                required: true,
              })}
            />
            {errors.sensor_license_cost?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Site License Cost</Label>
          <Col>
            <input
              placeholder="site license cost"
              type="text"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("site_license_cost", {
                required: true,
              })}
            />
            {errors.site_license_cost?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "10px" }}>
          <Label sm={3}>Sensor Cloud License Cost</Label>
          <Col>
            <input
              placeholder="Sensor cloud license cost"
              type="text"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("sensor_cloud_license_cost", {
                required: true,
              })}
            />
            {errors.sensor_cloud_license_cost?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <Label for="currency" sm={3}>
        Currency
        </Label>
        <FormGroup row style={{ marginBottom: "10px" }}>
        <Col>
          <select
              className="form-control"
              {...register("currency", {
                required: true,
              })}
            >
              <option value=""> --Select Currency Type--</option>
              <option value="AED">AED</option>
              <option value="USD">USD</option>
              <option value="INR">INR</option>
            </select>
            {errors.currency?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Remarks</Label>
          <Col>
            <textarea
              placeholder="remarks"
              type="text"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("remarks", {
                required: true,
              })}
            />
            {errors.remarks?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <ModalFooter>
          <Button
            type="submit"
            disabled={waitingForAxios}
            className="w-xs waves-effect waves-light me-1"
          >
            {waitingForAxios ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            type="button"
            color="danger"
            className="w-xs waves-effect waves-light me-1"
            onClick={props.toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Form>
      {/* } */}
    </Modals>
  );
}

export default EditPricing;
