import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notifications:[],
    notifications_count:0
};

const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers:{
        addNotifications(state,action) {
            state.notifications=action.payload
            state.notifications_count = action.payload.length
        },
        addNewNotifications(state,action) {
            state.notifications=[...state.notifications,action.payload]
            state.notifications_count++
        },
        updateNotifications(state,action) {
            state.notifications=state.notifications.map((data) => data.id === action.payload.id ? action.payload : data)
        },
        deleteNotifications(state,action) {
            state.notifications=state.notifications.filter((data) => data.id !== action.payload)
            state.notifications_count--
        },
        removeAllNotification(state,action) {
            state.notifications=[]
            state.notifications_count=0
        },
        readNotification(state, action) {
            state.notifications_count = state.notifications_count - 1;
          },

    }
})

export const {  deleteNotifications,
                updateNotifications,
                addNewNotifications,
                addNotifications,
                removeAllNotification,
                readNotification
            } = notificationSlice.actions;
export default notificationSlice.reducer;