import { useState, useRef, useEffect } from 'react';
import { io } from "socket.io-client";
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { wsUrl } from '../../helpers/baseURL';
import { setDevice } from '../../store/websocket';
import { RightSideToastr } from '../../helpers/Alert';
// import { baseURL } from '../helpers/baseURL';

const WebsocketProvider = ({ children }) => {
	const auth = useSelector((state) => state.authSlice);
	const dispatch = useDispatch();
	// const [webSocketUniqueRoom] = useState(uuidv4());

	const clientRef = useRef(null);
	// const [WebsocketState, setWebsocketState] = useState(null);
	const [isOpen, setIsOpen] = useState(false);


	useEffect(() => {
		if (auth.loggedIn) {
			const token = Cookies.get("token");

			const room = `updates`

			let url = `${wsUrl}/socket_connections/webapp`;


			const socket = io(url, {
				query: { room }, // Add room parameter to the connection
				extraHeaders: {
					token: token, // Pass the JWT token in headers
				},
			});
			clientRef.current = socket

			const handleSocketMessages = (message) => {
				try {
					const parsedMessage = typeof message === 'string' ? JSON.parse(message) : message;
					switch (parsedMessage?.type) {
						case "device_online_status":
							dispatch(setDevice(parsedMessage?.message))
							const alert_message = `The Device ${parsedMessage.message.device_id} ${parsedMessage.message.online_status ? 'is Online!' : 'has gone Offline!'}`;
							RightSideToastr(parsedMessage.message.online_status ? 'success' : 'error', alert_message)

							break;


						default:
							console.warn('Unhandled message type:', parsedMessage.type);
					}

				} catch (err) {
					console.error('Error handling socket message:', err);
				}
			};

			// Handle connection events
			socket.on('connect', () => {
				setIsOpen(true);
			});

			socket.on('disconnect', () => {
				setIsOpen(false);
			});

			socket.on(room, (message) => {
				handleSocketMessages(message);
			});

			// Clean up on unmount
			return () => {
				//   setWebsocketState('cleanup');
				socket.disconnect();
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.loggedIn]);

	// /* eslint-disable consistent-return */
	// useEffect(() => {
	//     if (auth.loggedIn) {
	// 		const wsUrl = baseURL.replace('http', 'ws');
	// 		const token = Cookies.get('token');
	// 		const url = `${wsUrl}/ws/web_app?token=${token}`;
	// 		if (waitingToReconnect) {
	// 			return;
	// 		}
	// 		// Only set up the websocket once
	// 		if (!clientRef.current) {
	// 			const client = new WebSocket(url);
	// 			clientRef.current = client;
	// 			window.client = client;
	// 			client.onerror = (e) => console.error(e);
	// 			client.onopen = () => {
	// 				setIsOpen(true);
	// 				setWebsocketState('ws opened');
	// 				// client.send('ping');
	// 			};

	// 			client.onclose = () => {
	// 				if (clientRef.current) {
	// 					// Connection failed
	// 					setWebsocketState('ws closed by server');
	// 				} else {
	// 					// Cleanup initiated from app side, can return here, to not attempt a reconnect
	// 					setWebsocketState('ws closed by app component unmount');
	// 					return;
	// 				}

	// 				if (waitingToReconnect) {
	// 					return;
	// 				}

	// 				// Parse event code and log
	// 				setIsOpen(false);
	// 				setWebsocketState('ws closed');

	// 				// Setting this will trigger a re-run of the effect,
	// 				// cleaning up the current websocket, but not setting
	// 				// up a new one right away
	// 				setWaitingToReconnect(true);

	// 				// This will trigger another re-run, and because it is false,
	// 				// the socket will be set up again
	// 				setTimeout(() => setWaitingToReconnect(null), 5000);
	// 			};

	// 			client.onmessage = (message) => {
	// 				// setWebsocketState("received message");
	// 				const tempMessage = JSON.parse(message.data);

	// 				try {
	// 					switch (tempMessage?.type) {
	//                         case "device_online_status":
	//                             dispatch(setDevice(tempMessage?.message))
	//                             const alert_message = `The Device ${tempMessage.message.device_id} ${tempMessage.message.online_status ? 'is Online!' : 'has gone Offline!'}`;
	//                             RightSideToastr(tempMessage.message.online_status? 'success' : 'error',alert_message)

	//                             break;
	// 					}
	// 				} catch {
	// 					// pass
	// 				}
	// 			};
	// 			return () => {
	// 				setWebsocketState('Cleanup');
	// 				clientRef.current = null;
	// 				client.close();
	// 			};
	// 		}
	// 	}

	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [waitingToReconnect,auth.loggedIn]);
	// /* eslint-enable consistent-return */
	return children;
};

export default WebsocketProvider;
