import React, { useState } from "react";
import { Button } from "reactstrap";
import { ReactComponent as SiteLogo } from "../../assets/icons/Site.svg";
import { ReactComponent as SensorLogo } from "../../assets/icons/Sensor.svg";
import IconButton from "@mui/material/IconButton";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { Tooltip } from "@material-ui/core";
import EditClient from "../../containers/Pages/Clients/ClientDetails/editClient";
import { useDispatch, useSelector } from "react-redux";
import Moments from "../../helpers/Moment";
import { authAxios } from "../../axiosInstance";
import Swal from "sweetalert2";
import { setDeviceDetail } from "../../store/client";
import Error from "../../helpers/Error";
import { Toastr } from "../../helpers/Alert";
import InitiateNewOrder from "./InitiateNewOrder";
import useUnAuthorizedHandler from "../../hooks/useUnAuthorizedHandler";

function LeftDetailComponent({ tableRef, activeTab, setRevenue }) {
  const logoutHandler = useUnAuthorizedHandler();

  const profile = useSelector((state) => state.authSlice.profile);

  const deviceDetail = useSelector((state) => state.ClientSlice.deviceDetail);
  const dispatch = useDispatch();
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);

  const updateModalToggle = () => {
    setUpdateModalShow(!updateModalShow);
  };
  const editModalToggle = () => {
    setEditModalShow(!editModalShow);
  };

  const deactivateHandler = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const url = `/abacicount/clientdevice/${deviceDetail?.id}`;
        authAxios
          .delete(url)
          .then((res) => {
            dispatch(
              setDeviceDetail({ ...deviceDetail, status: "Deactivated" })
            );
            Toastr("success", "Device deactivated successfully!");
          })
          .catch((err) => {
            let err_msg = Error(err, logoutHandler);
            Toastr("error", err_msg);
          });
      }
    });
  };

  return (
    <>
      {updateModalShow && (
        <InitiateNewOrder
          isOpen={updateModalShow}
          toggle={updateModalToggle}
          title={"Initiate New Order"}
          activeTab={activeTab}
          setRevenue={setRevenue}
          tableRef={tableRef}
        />
      )}
      {editModalShow && (
        <EditClient
          isOpen={editModalShow}
          toggle={editModalToggle}
          title={"Edit Client"}
        />
      )}

      <div
        className="d-flex justify-content-center gap-5"
        style={{ marginBottom: "-20px" }}
      >
        <div className="d-flex flex-column gap-1">
          <SiteLogo />
          <p className="text-center">
            {deviceDetail?.total_no_of_sites
              ? deviceDetail.total_no_of_sites
              : 0}
          </p>
        </div>
        <div className="d-flex flex-column gap-1">
          <SensorLogo />
          <p className="text-center">
            {deviceDetail?.total_no_of_sensors
              ? deviceDetail?.total_no_of_sensors
              : 0}
          </p>
        </div>
        {deviceDetail?.status === "Active" && profile.user_type === 'Admin' && (
          <div style={{ marginRight: "-80px", marginTop: "10px" }}>
            <Button
              type="button"
              color="light"
              size="sm"
              disabled={profile.user_type !== "Admin"}
              onClick={updateModalToggle}
              className="w-xs waves-effect waves-light me-1 "
            >
              Initiate New Order
            </Button>
          </div>
        )}
      </div>
      <hr />
      <table
        cellSpacing="0"
        cellPadding="6"
        style={{ width: "100%", marginBottom: "40px" }}
      >
        <tbody>
          {/* <tr className="text-muted">
            <td>Schema Name : {deviceDetail?.schema_name}</td>
          </tr> */}
          <tr className="text-muted">
            <td>Device ID : {deviceDetail?.device_id}</td>
          </tr>
          <tr className="text-muted">
            <td>
              Cloud Access No : {deviceDetail?.cloud_access_unique_number}
            </td>
          </tr>
          <tr className="text-muted">
            <td>Timezone : {deviceDetail?.device_timezone}</td>
          </tr>

          <tr className="text-muted">
            <td>
              Cloud sync valid upto :{" "}
              {deviceDetail?.cloud_sync_valid_up_to
                ? deviceDetail.cloud_sync_valid_up_to
                : "----"}
            </td>
          </tr>
          <tr className="text-muted">
            <td>
              Created by :{" "}
              {deviceDetail?.created_by?.full_name
                ? deviceDetail.created_by?.full_name
                : "----"}
            </td>
          </tr>
          <tr className="text-muted">
            <td>
              Created Date :{" "}
              {deviceDetail?.created_date
                ? Moments(deviceDetail.created_date, "datetime")
                : "----"}
            </td>
          </tr>
          <tr className="text-muted">
            <td>
              Modified by :{" "}
              {deviceDetail?.modified_by ? deviceDetail.modified_by : "----"}
            </td>
          </tr>
          <tr className="text-muted">
            <td>
              Modified Date :{" "}
              {deviceDetail?.modified_date
                ? Moments(deviceDetail.modified_date, "datetime")
                : "----"}
            </td>
          </tr>
          <tr className="text-muted">
            <td>
              Account Status :
              <span
                style={{ marginLeft: "5px" }}
                className={
                  deviceDetail?.status === "Active"
                    ? "badge badge-soft-success"
                    : "badge badge-soft-danger "
                }
              >
                {deviceDetail?.status}
              </span>
            </td>
          </tr>
          <tr className="text-muted">
            <td>
              Device connection status :
              <span
                style={{ marginLeft: "5px" }}
                className={
                  deviceDetail?.online_status
                    ? "badge badge-soft-success"
                    : "badge badge-soft-danger "
                }
              >
                {deviceDetail?.online_status ? "Online" : "Offline"}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        className=""
        style={{
          visibility: deviceDetail?.status === "Deactivated" && "hidden",
        }}
      >
        <div className="d-flex justify-content-center gap-3">
          {/* <Tooltip title="Update Client">
            <IconButton onClick={updateModalToggle}>
              <RestartAltIcon />
            </IconButton>
          </Tooltip> */}

          {/* <Tooltip title="Edit Client">
            <IconButton onClick={editModalToggle} disabled={profile.user_type !== 'Admin'}>
              <EditIcon />
            </IconButton>
          </Tooltip> */}

          <Tooltip
            title="Deactivate Device"
            onClick={deactivateHandler}
            disabled={profile.user_type !== "Admin"}
          >
            <IconButton>
              <PersonOffIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </>
  );
}

export default LeftDetailComponent;
