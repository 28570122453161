import React, { useRef, useState } from "react";
import { exportData } from "../../../../helpers/fileDownload";
import { authAxios } from "../../../../axiosInstance";
import { tableIcons } from "../../../../helpers/MaterialIcons";
import MaterialTable from "material-table";
import { useParams } from "react-router-dom";
import Moments from "../../../../helpers/Moment";
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";
import Error from "../../../../helpers/Error";
import { Toastr } from "../../../../helpers/Alert";
import AttachmentIcon from "@mui/icons-material/Attachment";
import useUnAuthorizedHandler from "../../../../hooks/useUnAuthorizedHandler";
import ReportInfoModal from "./reportInfoModal";

function TableComponent({ activeTab, tableRef }) {
  const logoutHandler = useUnAuthorizedHandler();
  const profile = useSelector((state) => state.authSlice.profile);
  const OrderType = {
    "On-prem": "Device License",
    Upgrade: "Upgrade",
    Both: "Both",
    Cloud: "Cloud Sync",
  };
  const { id } = useParams();
  const deviceDetail = useSelector((state) => state.ClientSlice.deviceDetail);
  const urlBackup = useRef("");
  const [reportModalShow, setReportShow] = useState(false);
  const [reportFiles, setReportFiles] = useState(null);
  const totalRecordsCount = useRef(0);
  const [loader, setLoader] = useState(false);

  const reportModalToggle = (data, isFromTable) => {
    setReportShow(!reportModalShow);

    setReportFiles(isFromTable ? data : null);
  };


  const UpdateHandler = (id) => {
    setLoader(true);
    const url = `/abacicount/deviceorderrud/${id}`;
    authAxios
      .patch(url, { status: "Cancelled" })
      .then((response) => {
        Toastr("success", "The Invoice order cancelled");
        setLoader(false);
        tableRef.current.onQueryChange();
        window.location.reload();
      })
      .catch((error) => {
        setLoader(false);

        const err_msg = Error(error, logoutHandler);
        Toastr("error", err_msg);
      });
  };
  const tableData = [
    {
      method: "get",
      url: `deviceinfolist/${id}`,
      column: [
        {
          title: "Sl No",
          field: "sl_no",
          width: "10%",
        },
        {
          title: "Log",
          field: "log",
        },
        {
          title: "Created Date",
          field: "created_date",
          render: (rowData) => <>{Moments(rowData.created_date, "datetime")}</>,
        },
      ],
    },
   
    {
      method: "get",
      url: `deviceorderlist/${id}`,
      column: [
        {
          title: "Ref No",
          field: "reference_number",
          render: (rowData) => (
            <>{rowData.reference_number ? rowData.reference_number : "----"}</>
          ),
        },

        {
          title: "No Of Sites",
          field: "no_of_sites",
        },
        {
          title: "No Of Sensors",
          field: "no_of_sensors",
        },
        {
          title: "Order Type",
          field: "order_type",
          render: (rowData) => (
            <>{rowData.order_type ? OrderType[rowData.order_type] : "----"}</>
          ),
        },
        {
          title: "Remarks",
          render: (rowData) => (
            <>{rowData?.remarks ? rowData.remarks : "----"}</>
          ),
        },
        {
          title: "Version",
          render: (rowData) => (
            <>{rowData?.version?.version ? rowData.version.version : "----"}</>
          ),
        },
        {
          title: "Purchase Date",
          field: "created_date",
          render: (rowData) => (
            <>
              {rowData.created_date
                ? Moments(rowData.created_date, "datetime")
                : "----"}
            </>
          ),
        },
        {
          title: "Amount",
          field: "amount",
        },
        {
          title: "Status",
          field: "status",
        },
      
      ],
    },
    {
      method: "get",
      url: `reportlog/${id}`,
      column: [
        {
          title: "Sl No",
          field: "sl_no",
          width: "10%",
        },
        {
          title: "Log",
          field: "log",
        },
        {
          title: "Created Date",
          field: "created_date",
          render: (rowData) => <>{Moments(rowData.created_date, "datetime")}</>,
        },
      ],
    },
    {
      method: "get",
      url: `device_connection_log_list`,
      column: [
        {
          title: "Sl No",
          field: "sl_no",
          width: "10%",
          sorting:false
        },
        {
          title: "Remarks",
          field: "remarks",
        },
        {
          title: "Connection Status",
          field: "is_connected",
          render:rowData =>  <span
          style={{ marginLeft: "5px" }}
          className={
            rowData?.is_connected 
              ? "badge badge-soft-success"
              : "badge badge-soft-danger "
          }
        >
          {rowData?.is_connected ? "Online" : "Offline"}
        </span>
        },
        {
          title: "Event Time",
          field: "event_time",
          render: (rowData) => <>{Moments(rowData.event_time, "datetime")}</>,
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      {reportModalShow && (
        <ReportInfoModal
          isOpen={reportModalShow}
          toggle={reportModalToggle}
          reportFiles={reportFiles}
          title={"Report Attachments"}
        />
      )}
      <div style={{ overflowY: "auto" }}>
        <MaterialTable
          title=" "
          columns={tableData[activeTab].column}
          tableRef={tableRef}
          isLoading={loader}
          data={(query) => {
            return new Promise((resolve, reject) => {
              let orderBy = "";
              let url = "";
              let ordering = "";
              let search = "";
              if (query.search) {
                search = query.search;
              }

              if (query.orderBy && (activeTab === 0 || activeTab === 1)) {
                orderBy =
                  query.orderDirection === "asc"
                    ? `&ordering=-${query.orderBy?.field}`
                    : `&ordering=${query.orderBy?.field}`;
              } else {
                orderBy =
                  query.orderDirection === "asc"
                    ? (ordering = `-${query.orderBy?.field}`)
                    : (ordering = query.orderBy?.field);
              }

              url = `/abacicount/${tableData[activeTab].url}?limit=${query.pageSize}&offset=${query.pageSize * query.page}`;

              if(orderBy){
                url+=`&order_by=${orderBy}`
              }
              if(activeTab === 1){
                url+=`&client=${id}`
              }
              if(activeTab === 3){
                url+=`&device_id=${id}`
              }

              if(search){
                // console.log(search)
                url+=`&search=${search}`
              }

              urlBackup.current = url;


              authAxios[tableData[activeTab].method](url)
                .then((response) => {
                  totalRecordsCount.current = response.data?.count;
                  const tempList = response.data?.results.map((data, idx) => ({
                    ...data,
                    sl_no: idx + 1,
                  }));

                  resolve({
                    data: tempList,
                    page: query.page,
                    totalCount: response.data?.count,
                  });
                })
                .catch((error) => {
                  const err_msg = Error(error, logoutHandler);
                  Toastr("error", err_msg);
                  reject({
                    data: [],
                    page: query.page,
                    totalCount: 0,
                  });
                });
            });
          }}
          actions={[
            (rowData) => ({
              icon: CancelIcon,
              tooltip: "Cancel",
              isFreeAction: true,
              onClick: (event, rowData) => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, Cancel it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    UpdateHandler(rowData.id);
                    // deleteClientData(rowData?.id)
                  }
                });
                // setDeleteModal({ id: rowData?.id, toggle: true });
              },
              hidden:
                rowData.status === "Cancelled" ||
                activeTab !== 1 ||
                profile.user_type !== "Admin" ||
                deviceDetail?.status !== "Active",
            }),
            {
              icon: AttachmentIcon,
              tooltip: "View Attachments",
              onClick: (event, rowData) => {
                reportModalToggle(rowData.files_log, true);
              },
              hidden: activeTab !== 2,
            },
          ]}
          options={{
            exportFileName: "Users List",
            exportAllData: true,
            actionsColumnIndex: -1,
            exportButton: true,
            headerStyle: {
              zIndex: 0,
            },
            exportCsv: async () =>
              exportData(
                "csv_download",
                urlBackup.current,
                totalRecordsCount.current,
                "Client List"
              ),
            exportPdf: async () =>
              exportData(
                "pdf_download",
                urlBackup.current,
                totalRecordsCount.current,
                "Client List"
              ),
            debounceInterval: 500,
            filtering: false,
          }}
          icons={tableIcons}
        />
      </div>
    </React.Fragment>
  );
}

export default TableComponent;
