import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clients:null,
    clientDetail:null,
    deviceDetail:null
};

const ClientSlice = createSlice({
    name: 'clients',
    initialState,
    reducers:{
        addClients(state,action) {
            state.clients=action.payload
        },
        addNewClients(state,action) {
            state.clients=[...state.clients,action.payload]
        },
        updateClients(state,action) {
            state.clients=state.clients.map((data) => data.id === action.payload.id ? action.payload : data)
        },
        deleteClients(state,action) {
            state.clients=state.clients.filter((data) => data.id !== action.payload)
        },
        setClientDetail(state,action){
            state.clientDetail=action.payload
        },
        updateClientDetail(state,action){
            state.clientDetail={...state.clientDetail,...action.payload}
        },
        setDeviceDetail(state,action){
            state.deviceDetail=action.payload
        },
        updateCloudStatusDevice(state,action){
            state.deviceDetail={...state.deviceDetail,online_status:action.payload}
        },

    }
})

export const {  deleteClients,
                updateClients,
                addNewClients,
                addClients,
                setClientDetail,
                updateClientDetail,
                setDeviceDetail,
                updateCloudStatusDevice
            } = ClientSlice.actions;
export default ClientSlice.reducer;