import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import Modals from "../../../../components/Modal";
import { authAxios } from "../../../../axiosInstance";
import Error from "../../../../helpers/Error";
import { ErrorAlert } from "../../../../helpers/Alert";
import {
  numberInvalidCharacterPreventer,
  unsecuredCopyToClipboard,
} from "../../../../helpers/functions";
import useUnAuthorizedHandler from "../../../../hooks/useUnAuthorizedHandler";

function GenerateToken(props) {
  const logoutHandler = useUnAuthorizedHandler();
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const [inputValues, setInputValues] = useState("");
  const [tokenList, setTokenList] = useState([]);
  const [isCopied, setIsCopied] = useState(false);

  const onSubmit = async () => {
    setwaitingForAxios(true);
    let url = `/version_management/get_temp_tokens?validity_minutes=${inputValues}`;
    authAxios
      .get(url)
      .then((response) => {
        setwaitingForAxios(false);
        setTokenList(response.data.tokens);
      })
      .catch((error) => {
        setwaitingForAxios(false);
        let error_msg = Error(error, logoutHandler);
        ErrorAlert(error_msg);
      });
  };

  const onChangeFunc = (value) => {
    if (value === "" || (value > 0 && value < 31)) {
      setInputValues(value);
    }
  };
  const clickHandler = async (data) => {
    if (window.isSecureContext && navigator.clipboard) {
      return await navigator.clipboard.writeText(data);
    } else {
      unsecuredCopyToClipboard(data);
    }
  };
  const handleCopyClick = (text) => {
    // Asynchronously call copyTextToClipboard
    clickHandler(text)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
      });
  };

  return (
    <Modals
      isOpen={props.isOpen}
      toggle={props.toggle}
      title={props.title}
      size={props.size}
    >
      {tokenList.length === 0 ? (
        <Form>
          <FormGroup style={{ marginBottom: "5px" }}>
            <Label>Please enter timeout in minutes (1 -30) !</Label>
            <Col>
              <input
                placeholder=""
                type="number"
                style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                className="form-control"
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) => {
                  numberInvalidCharacterPreventer(e);
                }}
                value={inputValues}
                onChange={(e) => onChangeFunc(e.target.value)}
              />

              {!inputValues && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          <ModalFooter>
            <Button
              type="submit"
              disabled={waitingForAxios || !inputValues}
              className="w-xs waves-effect waves-light me-1"
              onClick={() => onSubmit()}
            >
              {waitingForAxios ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                "Generate"
              )}
            </Button>
            <Button
              type="button"
              color="danger"
              className="w-xs waves-effect waves-light me-1"
              onClick={props.toggle}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      ) : (
        <div
          style={{ minHeight: "250px", paddingTop: "20px", fontWeight: "bold" }}
        >
          <ul>
            {tokenList.map((data, index) => {
              return (
                <li>
                  {`Token ${index + 1} : ${data[`token${index + 1}`]}`}{" "}
                  <Tooltip title={isCopied ? "Text Copied!!" : "Copy"} arrow>
                    <IconButton
                      size="small"
                      onClick={() => handleCopyClick(data[`token${index + 1}`])}
                    >
                      <ContentCopyIcon
                        fontSize="inherit"
                        style={{ height: "10px", width: "10px" }}
                      />
                    </IconButton>
                  </Tooltip>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </Modals>
  );
}

export default GenerateToken;
