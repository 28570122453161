const baseURLFunc = () => {
  const url = window.location.origin.split(":3001")[0];
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return url + ":8000/api"; // Use http://localhost:8000 for development
  } else {
    return url + "/api"; // Use the production origin
  }
};

const cloudUrlFunc = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return 'http://admin-dev.abacicount.com:8000/api/abacicount/cloudaccesstoclient'
  } else {
    return 'https://admin.abacicount.com/api/abacicount/cloudaccesstoclient'; // Use the production origin
  }
};

const wsUrlFunc = () => {
  return "https://admin.abacicount.com"; 
};

export const baseURL = baseURLFunc();
export const wsUrl = wsUrlFunc();
export const cloudUrl = cloudUrlFunc()
