import moment from "moment";

export const APP_NAME = "abacicount";

export const Color = [
  "#1cbb8c",
  "#008FFB",
  "#FF0000",
  "#A58CFF",
  "#5664d2",
  "#260e12",
  "#eeb902",
  "#ABCB35",
];
export const ButtonColors = ["#1976D2", "#74788D", "#1CBB8C", "#d33"];

export const ranges = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
  "Last 7 Days": [moment().subtract(6, "days"), moment()],
  "Last 30 Days": [moment().subtract(29, "days"), moment()],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Last Month": [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month"),
  ],
};

export function truncateString(str) {
  let temp = str.slice(0, 40) + "...";
  return temp;
}

export const pageSizeOptions = [5, 10, 20, 50];

export const csvLimit = 50000;

export const pdfLimit = 5000;
export const currency = "USD";
export const calendar_style = {
  calendar_icon: { display: "flex", marginBottom: "-55px", width: "50%" },
  calendar_text_container: { zIndex: "1", marginBottom: "-40px" },
  calendar_text: { marginTop: "17px", paddingRight: "20px" },
};

export const yearranges = {
  "Current Month": [moment().startOf("month"), moment()],
  "Current Year": [moment().startOf("year"), moment()],
  "Last 30 Days": [moment().subtract(30, "days"), moment()],
  "Last Month": [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month"),
  ],
  "Last Year": [
    moment().subtract(1, "year").startOf("year"),
    moment().subtract(1, "year").endOf("year"),
  ],
  "Last 365 Days": [moment().subtract(364, "days"), moment()],
};

export const country_list = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const industryList = [
  {
    name: "Accounting",
    label: "Accounting",
    value: "Accounting",
  },
  {
    name: "Airlines/Aviation",
    label: "Airlines/Aviation",
    value: "Airlines/Aviation",
  },
  {
    name: "Alternative Dispute Resolution",
    label: "Alternative Dispute Resolution",
    value: "Alternative Dispute Resolution",
  },
  {
    name: "Alternative Medicine",
    label: "Alternative Medicine",
    value: "Alternative Medicine",
  },
  {
    name: "Animation",
    label: "Animation",
    value: "Animation",
  },
  {
    name: "Apparel/Fashion",
    label: "Apparel/Fashion",
    value: "Apparel/Fashion",
  },
  {
    name: "Architecture/Planning",
    label: "Architecture/Planning",
    value: "Architecture/Planning",
  },
  {
    name: "Arts/Crafts",
    label: "Arts/Crafts",
    value: "Arts/Crafts",
  },
  {
    name: "Automotive",
    label: "Automotive",
    value: "Automotive",
  },
  {
    name: "Aviation/Aerospace",
    label: "Aviation/Aerospace",
    value: "Aviation/Aerospace",
  },
  {
    name: "Banking/Mortgage",
    label: "Banking/Mortgage",
    value: "Banking/Mortgage",
  },
  {
    name: "Biotechnology/Greentech",
    label: "Biotechnology/Greentech",
    value: "Biotechnology/Greentech",
  },
  {
    name: "Broadcast Media",
    label: "Broadcast Media",
    value: "Broadcast Media",
  },
  {
    name: "Building Materials",
    label: "Building Materials",
    value: "Building Materials",
  },
  {
    name: "Business Supplies/Equipment",
    label: "Business Supplies/Equipment",
    value: "Business Supplies/Equipment",
  },
  {
    name: "Capital Markets/Hedge Fund/Private Equity",
    label: "Capital Markets/Hedge Fund/Private Equity",
    value: "Capital Markets/Hedge Fund/Private Equity",
  },
  {
    name: "Chemicals",
    label: "Chemicals",
    value: "Chemicals",
  },
  {
    name: "Civic/Social Organization",
    label: "Civic/Social Organization",
    value: "Civic/Social Organization",
  },
  {
    name: "Civil Engineering",
    label: "Civil Engineering",
    value: "Civil Engineering",
  },
  {
    name: "Commercial Real Estate",
    label: "Commercial Real Estate",
    value: "Commercial Real Estate",
  },
  {
    name: "Computer Games",
    label: "Computer Games",
    value: "Computer Games",
  },
  {
    label: "Computer Hardware",
    value: "Computer Hardware",
    name: "Computer Hardware",
  },
  {
    name: "Computer Networking",
    label: "Computer Networking",
    value: "Computer Networking",
  },
  {
    name: "Computer Software/Engineering",
    label: "Computer Software/Engineering",
    value: "Computer Software/Engineering",
  },
  {
    name: "Computer/Network Security",
    label: "Computer/Network Security",
    value: "Computer/Network Security",
  },
  {
    name: "Construction",
    label: "Construction",
    value: "Construction",
  },
  {
    name: "Consumer Electronics",
    label: "Consumer Electronics",
    value: "Consumer Electronics",
  },
  {
    name: "Consumer Goods",
    label: "Consumer Goods",
    value: "Consumer Goods",
  },
  {
    name: "Consumer Services",
    label: "Consumer Services",
    value: "Consumer Services",
  },
  {
    name: "Cosmetics",
    label: "Cosmetics",
    value: "Cosmetics",
  },
  {
    name: "Dairy",
    label: "Dairy",
    value: "Dairy",
  },
  {
    name: "Defense/Space",
    label: "Defense/Space",
    value: "Defense/Space",
  },
  {
    name: "Design",
    label: "Design",
    value: "Design",
  },
  {
    name: "E-Learning",
    label: "E-Learning",
    value: "E-Learning",
  },
  {
    name: "Education Management",
    label: "Education Management",
    value: "Education Management",
  },
  {
    name: "Electrical/Electronic Manufacturing",
    label: "Electrical/Electronic Manufacturing",
    value: "Electrical/Electronic Manufacturing",
  },
  {
    name: "Entertainment/Movie Production",
    label: "Entertainment/Movie Production",
    value: "Entertainment/Movie Production",
  },
  {
    name: "Environmental Services",
    label: "Environmental Services",
    value: "Environmental Services",
  },
  {
    name: "Events Services",
    label: "Events Services",
    value: "Events Services",
  },
  {
    name: "Executive Office",
    label: "Executive Office",
    value: "Executive Office",
  },
  {
    name: "Facilities Services",
    label: "Facilities Services",
    value: "Facilities Services",
  },
  {
    name: "Farming",
    label: "Farming",
    value: "Farming",
  },
  {
    name: "Financial Services",
    label: "Financial Services",
    value: "Financial Services",
  },
  {
    name: "Fine Art",
    label: "Fine Art",
    value: "Fine Art",
  },
  {
    name: "Fishery",
    label: "Fishery",
    value: "Fishery",
  },
  {
    name: "Food Production",
    label: "Food Production",
    value: "Food Production",
  },
  {
    name: "Food/Beverages",
    label: "Food/Beverages",
    value: "Food/Beverages",
  },
  {
    name: "Fundraising",
    label: "Fundraising",
    value: "Fundraising",
  },
  {
    name: "Furniture",
    label: "Furniture",
    value: "Furniture",
  },
  {
    name: "Gambling/Casinos",
    label: "Gambling/Casinos",
    value: "Gambling/Casinos",
  },
  {
    name: "Glass/Ceramics/Concrete",
    label: "Glass/Ceramics/Concrete",
    value: "Glass/Ceramics/Concrete",
  },
  {
    name: "Government Administration",
    label: "Government Administration",
    value: "Government Administration",
  },
  {
    name: "Government Relations",
    label: "Government Relations",
    value: "Government Relations",
  },
  {
    name: "Graphic Design/Web Design",
    label: "Graphic Design/Web Design",
    value: "Graphic Design/Web Design",
  },
  {
    name: "Health/Fitness",
    label: "Health/Fitness",
    value: "Health/Fitness",
  },
  {
    name: "Higher Education/Acadamia",
    label: "Higher Education/Acadamia",
    value: "Higher Education/Acadamia",
  },
  {
    name: "Hospital/Health Care",
    label: "Hospital/Health Care",
    value: "Hospital/Health Care",
  },
  {
    name: "Hospitality",
    label: "Hospitality",
    value: "Hospitality",
  },
  {
    name: "Human Resources/HR",
    label: "Human Resources/HR",
    value: "Human Resources/HR",
  },
  {
    name: "Import/Export",
    label: "Import/Export",
    value: "Import/Export",
  },
  {
    name: "Individual/Family Services",
    label: "Individual/Family Services",
    value: "Individual/Family Services",
  },
  {
    name: "Industrial Automation",
    label: "Industrial Automation",
    value: "Industrial Automation",
  },
  {
    name: "Information Services",
    label: "Information Services",
    value: "Information Services",
  },
  {
    name: "Information Technology/IT",
    label: "Information Technology/IT",
    value: "Information Technology/IT",
  },
  {
    name: "Insurance",
    label: "Insurance",
    value: "Insurance",
  },
  {
    name: "International Affairs",
    label: "International Affairs",
    value: "International Affairs",
  },
  {
    name: "International Trade/Development",
    label: "International Trade/Development",
    value: "International Trade/Development",
  },
  { label: "Accounting", value: "Accounting", name: "Accounting" },
  {
    name: "Investment Banking/Venture",
    label: "Investment Banking/Venture",
    value: "Investment Banking/Venture",
  },
  {
    name: "Investment Management/Hedge Fund/Private Equity",
    label: "Investment Management/Hedge Fund/Private Equity",
    value: "Investment Management/Hedge Fund/Private Equity",
  },
  {
    name: "Judiciary",
    label: "Judiciary",
    value: "Judiciary",
  },
  {
    name: "Law Enforcement",
    label: "Law Enforcement",
    value: "Law Enforcement",
  },
  {
    name: "Law Practice/Law Firms",
    label: "Law Practice/Law Firms",
    value: "Law Practice/Law Firms",
  },
  {
    name: "Legal Services",
    label: "Legal Services",
    value: "Legal Services",
  },
  {
    name: "Legislative Office",
    label: "Legislative Office",
    value: "Legislative Office",
  },
  {
    name: "Leisure/Travel",
    label: "Leisure/Travel",
    value: "Leisure/Travel",
  },
  {
    name: "Library",
    label: "Library",
    value: "Library",
  },
  {
    name: "Logistics/Procurement",
    label: "Logistics/Procurement",
    value: "Logistics/Procurement",
  },
  {
    name: "Luxury Goods/Jewelry",
    label: "Luxury Goods/Jewelry",
    value: "Luxury Goods/Jewelry",
  },
  {
    name: "Machinery",
    label: "Machinery",
    value: "Machinery",
  },
  {
    name: "Management Consulting",
    label: "Management Consulting",
    value: "Management Consulting",
  },
  {
    name: "Maritime",
    label: "Maritime",
    value: "Maritime",
  },
  {
    name: "Market Research",
    label: "Market Research",
    value: "Market Research",
  },
  {
    name: "Marketing/Advertising/Sales",
    label: "Marketing/Advertising/Sales",
    value: "Marketing/Advertising/Sales",
  },
  {
    name: "Mechanical or Industrial Engineering",
    label: "Mechanical or Industrial Engineering",
    value: "Mechanical or Industrial Engineering",
  },
  {
    name: "Media Production",
    label: "Media Production",
    value: "Media Production",
  },
  {
    name: "Medical Equipment",
    label: "Medical Equipment",
    value: "Medical Equipment",
  },
  {
    name: "Medical Practice",
    label: "Medical Practice",
    value: "Medical Practice",
  },
  {
    name: "Mental Health Care",
    label: "Mental Health Care",
    value: "Mental Health Care",
  },
  {
    name: "Military Industry",
    label: "Military Industry",
    value: "Military Industry",
  },
  {
    name: "Mining/Metals",
    label: "Mining/Metals",
    value: "Mining/Metals",
  },
  {
    name: "Motion Pictures/Film",
    label: "Motion Pictures/Film",
    value: "Motion Pictures/Film",
  },
  {
    name: "Museums/Institutions",
    label: "Museums/Institutions",
    value: "Museums/Institutions",
  },
  {
    name: "Music",
    label: "Music",
    value: "Music",
  },
  {
    name: "Nanotechnology",
    label: "Nanotechnology",
    value: "Nanotechnology",
  },
  {
    name: "Newspapers/Journalism",
    label: "Newspapers/Journalism",
    value: "Newspapers/Journalism",
  },
  {
    name: "Non-Profit/Volunteering",
    label: "Non-Profit/Volunteering",
    value: "Non-Profit/Volunteering",
  },
  {
    name: "Oil/Energy/Solar/Greentech",
    label: "Oil/Energy/Solar/Greentech",
    value: "Oil/Energy/Solar/Greentech",
  },
  {
    name: "Online Publishing",
    label: "Online Publishing",
    value: "Online Publishing",
  },
  {
    name: "Other Industry",
    label: "Other Industry",
    value: "Other Industry",
  },
  {
    name: "Outsourcing/Offshoring",
    label: "Outsourcing/Offshoring",
    value: "Outsourcing/Offshoring",
  },
  {
    name: "Package/Freight Delivery",
    label: "Package/Freight Delivery",
    value: "Package/Freight Delivery",
  },
  {
    name: "Packaging/Containers",
    label: "Packaging/Containers",
    value: "Packaging/Containers",
  },
  {
    name: "Paper/Forest Products",
    label: "Paper/Forest Products",
    value: "Paper/Forest Products",
  },
  {
    name: "Performing Arts",
    label: "Performing Arts",
    value: "Performing Arts",
  },
  {
    name: "Pharmaceuticals",
    label: "Pharmaceuticals",
    value: "Pharmaceuticals",
  },
  {
    name: "Philanthropy",
    label: "Philanthropy",
    value: "Philanthropy",
  },
  {
    name: "Photography",
    label: "Photography",
    value: "Photography",
  },
  {
    name: "Plastics",
    label: "Plastics",
    value: "Plastics",
  },
  {
    name: "Political Organization",
    label: "Political Organization",
    value: "Political Organization",
  },
  {
    name: "Primary/Secondary Education",
    label: "Primary/Secondary Education",
    value: "Primary/Secondary Education",
  },
  {
    name: "Printing",
    label: "Printing",
    value: "Printing",
  },
  {
    name: "Professional Training",
    label: "Professional Training",
    value: "Professional Training",
  },
  {
    name: "Program Development",
    label: "Program Development",
    value: "Program Development",
  },
  {
    name: "Public Relations/PR",
    label: "Public Relations/PR",
    value: "Public Relations/PR",
  },
  {
    name: "Public Safety",
    label: "Public Safety",
    value: "Public Safety",
  },
  {
    name: "Publishing Industry",
    label: "Publishing Industry",
    value: "Publishing Industry",
  },
  {
    name: "Railroad Manufacture",
    label: "Railroad Manufacture",
    value: "Railroad Manufacture",
  },
  {
    name: "Ranching",
    label: "Ranching",
    value: "Ranching",
  },
  {
    name: "Real Estate/Mortgage",
    label: "Real Estate/Mortgage",
    value: "Real Estate/Mortgage",
  },
  {
    name: "Recreational Facilities/Services",
    label: "Recreational Facilities/Services",
    value: "Recreational Facilities/Services",
  },
  {
    name: "Religious Institutions",
    label: "Religious Institutions",
    value: "Religious Institutions",
  },
  {
    name: "Renewables/Environment",
    label: "Renewables/Environment",
    value: "Renewables/Environment",
  },
  {
    name: "Research Industry",
    label: "Research Industry",
    value: "Research Industry",
  },
  {
    name: "Restaurants",
    label: "Restaurants",
    value: "Restaurants",
  },
  {
    name: "Retail Industry",
    label: "Retail Industry",
    value: "Retail Industry",
  },
  {
    name: "Security/Investigations",
    label: "Security/Investigations",
    value: "Security/Investigations",
  },
  {
    name: "Semiconductors",
    label: "Semiconductors",
    value: "Semiconductors",
  },
  {
    name: "Shipbuilding",
    label: "Shipbuilding",
    value: "Shipbuilding",
  },
  {
    name: "Sporting Goods",
    label: "Sporting Goods",
    value: "Sporting Goods",
  },
  {
    name: "Sports",
    label: "Sports",
    value: "Sports",
  },
  {
    name: "Staffing/Recruiting",
    label: "Staffing/Recruiting",
    value: "Staffing/Recruiting",
  },
  {
    name: "Supermarkets",
    label: "Supermarkets",
    value: "Supermarkets",
  },
  {
    name: "Telecommunications",
    label: "Telecommunications",
    value: "Telecommunications",
  },
  {
    name: "Textiles",
    label: "Textiles",
    value: "Textiles",
  },
  {
    name: "Think Tanks",
    label: "Think Tanks",
    value: "Think Tanks",
  },
  {
    name: "Tobacco",
    label: "Tobacco",
    value: "Tobacco",
  },
  {
    name: "Translation/Localization",
    label: "Translation/Localization",
    value: "Translation/Localization",
  },
  {
    name: "Transportation",
    label: "Transportation",
    value: "Transportation",
  },
  {
    name: "Utilities",
    label: "Utilities",
    value: "Utilities",
  },
  {
    name: "Venture Capital/VC",
    label: "Venture Capital/VC",
    value: "Venture Capital/VC",
  },
  {
    name: "Veterinary",
    label: "Veterinary",
    value: "Veterinary",
  },
  {
    name: "Warehousing",
    label: "Warehousing",
    value: "Warehousing",
  },
  {
    name: "Wholesale",
    label: "Wholesale",
    value: "Wholesale",
  },
  {
    name: "Wine/Spirits",
    label: "Wine/Spirits",
    value: "Wine/Spirits",
  },
  {
    name: "Wireless",
    label: "Wireless",
    value: "Wireless",
  },
  {
    name: "Writing/Editing",
    label: "Writing/Editing",
    value: "Writing/Editing",
  },
];

export const orderTypeOption = [
  { value: "On-prem", label: "Device License" },
  { value: "Cloud", label: "Upgrade Cloud Sync" },
  // { value: "Both", label: "Both" },
  // { value: "Upgrade", label: "Upgrade" },
];

export const subscriptionYears = [
  { value: "1", label: "1 Year" },
  { value: "2", label: "2 Year" },
  { value: "3", label: "3 Year" },
];