import React, { useEffect } from "react";
import { Spinner } from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useState } from "react";
import axios from "axios";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import { NotificationCard } from "./NotificationCard";
import { Toastr } from "../../helpers/Alert";
import { useDispatch, useSelector } from "react-redux";
import lootie from "../../assets/lotties/animation_lnsgqb9k.json";
import { Player } from "@lottiefiles/react-lottie-player";
import useUnAuthorizedHandler from "../../hooks/useUnAuthorizedHandler";
import { addNotifications, deleteNotifications, removeAllNotification } from "../../store/notifications";
import Error from "../../helpers/Error";

function Notifications() {
  const notification_count = useSelector(
    (state) => state.notificationSlice.notifications_count
  );
  const notification =  useSelector(
    (state) => state.notificationSlice.notifications
  );
  // const selected_site = useSelector((state) => state?.siteSlice?.selected_site);
  const logoutHandler = useUnAuthorizedHandler();

  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();
  const messagesHandler = () => {
    setMenu(false);
    navigate("/notification");
  };

  const removeNotification = (id) => {
    dispatch(deleteNotifications(id))
  };

  const clearAll = () => {

    if(notification.length > 0 || notification_count > 0){

      // let dataToBeSend={
      //   alerts_ids:socket?.notifications.map((data) => data.id)
      // }
      const url='/abacicount/alerts_clear'
      axios.post(url)
      .then((res) => {
        // let socketArrayLength = socket.notifications.length
        // dispatch(addNotification(notification_count - socketArrayLength));
        // socket.setNotifications([]);
        dispatch(removeAllNotification());
        // socket.setNotifications([]);
        // if()
        // notificationHandler();
      
      })
      .catch((err)=> {
        Toastr('error',Error(err,logoutHandler))
      })

    }

   
  };
  const notificationHandler = () => {
    setLoader(true);
    const url = `/abacicount/alerts_count`;
    axios
      .get(url)
      .then((response) => {
        setLoader(false);

        dispatch(addNotifications(response.data.data));
      })
      .catch((error) => {
        setLoader(false);

        const err_msg = Error(error, logoutHandler);
        Toastr("error", err_msg);
      });
  };

  useEffect(() => {
      notificationHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu((state) => !state)}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          <div>
            {/* {notification_count > 0  ? (
              <i
                className="ri-checkbox-blank-circle-fill"
                style={{
                  fontSize: "8px",
                  color: "red",
                  position: "absolute",
                  top: "21px",
                  right: "12px",
                }}
              ></i>
            ) : null} */}

              {notification_count > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    backgroundColor: "red",
                    width: "22px",
                    height: "20px",
                    marginLeft: "13px",
                    borderRadius: "50% 50%",
                  }}
                  // className="text-center"
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: "9.5px",
                      marginLeft:"2px",
                      marginTop: "3px",
                    }}
                  >
                  {notification_count > 99 ? "99+" : notification_count}
                  </p>
                </div>
              )}

            <NotificationsIcon />
          </div>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <div style={{ minWidth: "300px" }}>
            <div
              style={{
                width: "100%",
                height: "50px",
                borderRadius: "10px 10px 0px 0px",
                borderBottom: "1px solid #EFF2F7",
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span className="d-flex">
                {" "}
                <h6 style={{ color: "#8D9091", marginRight: "10px" }}>
                  Notifications{" "}
                </h6>
                {notification_count > 0 && (
                  <div className="d-flex mt-1 ">
                    <i
                      className="ri-checkbox-blank-circle-fill"
                      style={{
                        fontSize: "8px",
                        color: "#4F72B7",
                      }}
                    ></i>

                    <span style={{ fontSize: "12px", marginTop: "-3px" }}>
                      {notification_count}
                    </span>
                  </div>
                )}
              </span>
              {/* {notification_count !== 0 && notification_count !== null ? (
                <div className="d-flex ">
                  <i
                    className="ri-checkbox-blank-circle-fill"
                    style={{
                      fontSize: "8px",
                      color: "#4F72B7",
                    }}
                  ></i>

                  <span style={{ fontSize: "12px", marginTop: "-3px" }}>
                    {notification_count}
                  </span>
                </div>
              ) : null} */}
              <span
                style={{
                  color: "#4F72B7",
                  fontSize: "13px",
                  cursor: "pointer",
                  userSelect: "none",
                }}
                onClick={() => clearAll()}
              >
                Clear All
              </span>
            </div>
            <div
              className="msg_notificatins"
              style={{
                width: "100%",
                height: "300px",
                padding: "10px",
              }}
            >
              {loader ? (
                <>
                  <Spinner
                    animation="grow"
                    size="sm"
                    // className="position_center_class"
                    style={{
                      position: "absolute",
                      left: "48%",
                      top: "50%",
                      color: "#4F72B7",
                    }}
                  />
                </>
              ) : notification.length === 0 ? (
                <div className="position_center_class">
                  <Player
                    autoplay
                    loop
                    src={lootie}
                    style={{
                      height: "30px",
                      width: "30px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Notifications not found !
                  </span>
                </div>
              ) : (
                notification.map((data, index) => (
                  <NotificationCard
                    data={data}
                    key={data?.id}
                    removeNotification={removeNotification}
                  />
                ))
              )}
            </div>
            <div
              style={{
                width: "100%",
                height: "50px",
                borderRadius: "0px 0px 10px 10px ",
                borderTop: "1px solid #EFF2F7",
                paddingTop: "17px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className="d-flex"
                style={{
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={() => messagesHandler()}
              >
                <ArrowCircleRightIcon
                  style={{
                    fontSize: "12px",
                    marginTop: "7px",
                    marginRight: "5px",
                    color: "#4267B2",
                  }}
                />
                <p
                  style={{
                    fontSize: "12px",
                    marginTop: "4px",
                    marginRight: "5px",
                    color: "#4267B2",
                  }}
                >
                  View More
                </p>
              </div>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default Notifications;
