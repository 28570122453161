import React from "react";

//Import images
import errorImg from "../../../assets/images/error-img.png";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";

export default function ErrorPage(props) {
  const [splittedString, setSplittedString] = useState();
  const [errorMessage, setErrorMessage] = useState("Sorry, page not found");

  useEffect(() => {
    const splitted = props.error.toString().split("");

    if (splitted[0] === "0") {
      setSplittedString([4, 0, 4]);
    } else {
      setSplittedString(splitted);
    }

    if (props.error === 500 || props.error === 502) {
      setErrorMessage("Internal Server Error");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div
        className="text-center"
        style={{ position: "relative", top: "150px" }}
      >
        <div className="text-center my-5">
          <h1 className="fw-bold text-error">
            {splittedString && splittedString[0]}
            <span className="error-text">
              {splittedString && splittedString[1]}
              <img src={errorImg} alt="" className="error-img" />
            </span>{" "}
            {splittedString && splittedString[2]}
          </h1>
          <h3 className="text-uppercase">{errorMessage}</h3>
          <div className="mt-5 text-center">
            <Button
              className="btn btn-primary waves-effect waves-light"
              onClick={() => window.location.reload()}
            >
              Refresh
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
